<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <OForm :class="{submitting}" @submit="submit">
    <input type="text" class="hidden" name="email" autocomplete="username" :value="form.email">
    <OFormInput
      id="current-password"
      name="current-password"
      type="password"
      v-model="form.oldPassword"
      autocomplete="current-password"
      required
      placeholder="Enter your current password"
    >
      Current password
    </OFormInput>
    <OFormInput
      id="new-password"
      name="new-password"
      type="password"
      v-model="form.newPassword"
      autocomplete="new-password"
      required
      placeholder="Enter your new password"
    >
      New password
    </OFormInput>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Change password
      </OFormSubmitButton>
    </FormButtons>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'
import OFormInput from '@/objects/forms/OFormInput'
import { mapMutations } from 'vuex'

export default {
  name: 'CFormChangePassword',
  components: { OFormError, OFormSubmitButton, OForm, FormButtons, OFormInput },
  mixins: [apiForm],
  data: function () {
    return {
      form: {
        email: this.$store.getters.getEmail,
        oldPassword: null,
        newPassword: null
      }
    }
  },
  methods: {
    ...mapMutations(['setAuth']),
    submit () {
      this.$callApi(api => api.changePassword(this.form))
    }
  },
  formSubmitted () {
    this.setAuth({
      email: this.form.email,
      password: this.form.newPassword
    })
  }
}
</script>
